<template>
  <div class="RecruitManagement">
    <a-button-group style="margin-top:10px;">
      <a-button
        :class="index == 1 ? 'ant-btn-primary' : ''"
        @click="routerBtn(1)"
        >招聘管理</a-button
      >
      <a-button
        :class="index == 2 ? 'ant-btn-primary' : ''"
        @click="routerBtn(2)"
        >面试管理</a-button
      >
      <a-button
        :class="index == 3 ? 'ant-btn-primary' : ''"
        @click="routerBtn(3)"
        >简历库</a-button
      >
      <a-button
        :class="index == 4 ? 'ant-btn-primary' : ''"
        @click="routerBtn(4)"
        >人才库</a-button
      >
    </a-button-group>
    <div
      :style="{
        'border-top': '1px solid #E0E1E2',
        'margin-top': '-1px',
        'min-height': clientH
      }"
    >
      <router-view></router-view>
      <!-- <jobPool v-if="index==1"></jobPool>
               <talentPool v-else-if="index==2"></talentPool>
               <interviewManagement v-else-if="index==3"></interviewManagement>
               <CVPool v-else></CVPool> -->
    </div>
  </div>
</template>
<script>
// import jobPool from '@/pages/personnelModul/Recruit/Pool/JobPool';
// import talentPool from '@/pages/personnelModul/Recruit/Pool/TalentPool';
// import interviewManagement from '@/pages/personnelModul/Recruit/Pool/InterviewManagement';
// import CVPool from '@/pages/personnelModul/Recruit/Pool/CVPool';
export default {
  name: 'RecruitManagement',
  // components:{
  //     jobPool,
  //     talentPool,
  //     CVPool,
  //     interviewManagement,
  // },
  data () {
    return {
      index: 1,
      clientH: '500px',
      urlArr: [
        '/personnelModul/jobPool',
        '/personnelModul/interviewManagement',
        '/personnelModul/CVPool',
        '/personnelModul/talentPool'
      ]
    }
  },
  created () {
    if (this.$route.path == '/personnelModul/interviewManagement') {
      this.index = 2
    } else if (this.$route.path == '/personnelModul/jobPool') {
      this.index = 1
    } else if (this.$route.path == '/personnelModul/CVPool') {
      this.index = 3
    } else {
      this.index = 4
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 227 + 'px'
  },
  methods: {
    routerBtn (i) {
      console.log(i, 222222222)
      this.index = i
      let that = this
      that.$router.push({
        //核心语句
        path: that.urlArr[i - 1] //跳
      })
    }
    // <router-link to="/personnelModul/jobPool" type="primary">招聘管理</router-link>
    // <router-link to='/personnelModul/interviewManagement'>面试管理</router-link>
    // <router-link to='/personnelModul/CVPool'>简历库</router-link>
    // <router-link to='/personnelModul/talentPool'>人才库</router-link>
  }
}
</script>
<style lang="less">
.RecruitManagement {
  position: relative;
  background: #fff;
  border-radius: 4px;

  overflow-y: auto;
  user-select: none;
  font-size: 14px;
  color: #666666;
  padding: 15px;
}
</style>
